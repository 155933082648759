import { VisibilityLockIcon } from '@/icons';
import { Product } from '@/models';
import { useUserData } from '@/zustand';
import BadgeIcon from '@mui/icons-material/Badge';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import { Button, ButtonProps, Divider, Grid, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import React from 'react';
import { formatInteger } from 'src/logic/utils/numbers';
import { sm } from 'src/site-map';
import { MoreOptions } from 'src/ui/components/dumb';

type StatusIndicatorProps = {
  icon: React.ReactNode;
  text: string;
};

type ActionButtonProps = {
  text: string;
  onClick: () => void;
  icon: React.ReactNode;
  color: ButtonProps['color'];
};

const StatusIndicator = ({ icon, text }: StatusIndicatorProps) => (
  <Grid item sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
    {icon}
    <Typography variant='subtitle2' color='#8B8D98' fontWeight={500}>
      {text}
    </Typography>
  </Grid>
);

const DividerGrid = () => (
  <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
    <Divider orientation='vertical' sx={{ bgcolor: '#EAEEF6', height: '20px', mx: 2 }} />
  </Grid>
);

const StatusBar = ({ statuses, actions }: { statuses: StatusIndicatorProps[]; actions: ActionButtonProps[] }) => {
  return (
    <Grid
      container
      alignItems='center'
      justifyContent={'space-between'}
      sx={{ bgcolor: '#F6F8FC', p: 1, my: 0.5, mb: 2, borderRadius: '8px' }}
    >
      <Grid item>
        <Grid container spacing={2} alignItems='center'>
          {statuses.map((statusItem, index) => {
            return (
              <React.Fragment key={statusItem.text}>
                {index > 0 && <DividerGrid />}
                <StatusIndicator icon={statusItem.icon} text={statusItem.text} />
              </React.Fragment>
            );
          })}
        </Grid>
      </Grid>

      <Grid item>
        {actions.length > 0 && (
          <MoreOptions HideBackground>
            {actions.map(actionItem => {
              return (
                <Button
                  key={actionItem.text}
                  fullWidth
                  style={{ justifyContent: 'start' }}
                  startIcon={actionItem.icon}
                  color={actionItem.color}
                  onClick={actionItem.onClick}
                >
                  {actionItem.text}
                </Button>
              );
            })}
          </MoreOptions>
        )}
      </Grid>
    </Grid>
  );
};

const BuyerStatus = ({ productDetails }: { productDetails: Product }) => {
  const router = useRouter();

  const statuses = React.useMemo(() => {
    const result: StatusIndicatorProps[] = [];
    if (productDetails?.is_private_product) {
      result.push({
        text: 'Private',
        icon: <VisibilityLockIcon sx={{ color: '#0074FF', fontSize: '20px', fontWeight: 900 }} />,
      });
    } else {
      result.push({ text: 'Public', icon: <PublicIcon sx={{ color: '#0074FF', fontSize: '17px', mr: 0.5 }} /> });
    }

    if (productDetails?.catalog_item?.status === 'PUBLISHED') {
      result.push({ text: 'Published', icon: <PublicIcon sx={{ color: '#0074FF', fontSize: '14px', mr: 0.5 }} /> });
    } else {
      result.push({
        text: 'Not Published',
        icon: <PublicOffIcon sx={{ color: 'rgba(112, 176, 255, 1)', fontSize: '14px', mr: 0.5 }} />,
      });
    }

    const numberOfSellers = productDetails?.sellers?.length ?? 0;
    result.push({
      text: `${formatInteger(numberOfSellers)} Seller${numberOfSellers !== 1 ? 's' : ''}`,
      icon: <BadgeIcon sx={{ color: '#0074FF', fontSize: '19px', mr: 0.5 }} />,
    });

    return result;
  }, [productDetails]);

  const actions = React.useMemo(() => {
    const result: ActionButtonProps[] = [];

    if (productDetails?.can_edit_product_details) {
      result.push({
        text: 'Edit',
        onClick: () => router.push(sm.catalogue.products.editProduct(`${productDetails?.id}`).url),
        icon: <LinkOffIcon />,
        color: 'primary',
      });
    }

    return result;
  }, [productDetails]);

  return <StatusBar actions={actions} statuses={statuses} />;
};

const SellerStatus = ({ productDetails }: { productDetails: Product }) => {
  const router = useRouter();
  const { canUpdateCatalog } = useUserData();

  const statuses = React.useMemo(() => {
    const result: StatusIndicatorProps[] = [];
    if (productDetails?.catalog_item) {
      result.push({ text: 'Linked', icon: <LinkIcon color='success' sx={{ fontSize: '18px', mr: 0.5 }} /> });
    } else {
      result.push({ text: 'Not Linked', icon: <LinkOffIcon sx={{ color: '#8B8D98', fontSize: '18px', mr: 0.5 }} /> });
    }

    if (productDetails?.catalog_item?.status === 'PUBLISHED') {
      result.push({ text: 'Published', icon: <PublicIcon sx={{ color: '#0074FF', fontSize: '14px', mr: 0.5 }} /> });
    } else {
      result.push({
        text: 'Not Published',
        icon: <PublicOffIcon sx={{ color: 'rgba(112, 176, 255, 1)', fontSize: '14px', mr: 0.5 }} />,
      });
    }

    return result;
  }, [productDetails]);

  const actions = React.useMemo(() => {
    const result: ActionButtonProps[] = [];

    if (canUpdateCatalog) {
      result.push({
        text: 'Suggest Edits',
        onClick: () => router.push(sm.catalogue.products.editProduct(`${productDetails?.id}`).url),
        icon: <BorderColorIcon />,
        color: 'primary',
      });
    }
    if (productDetails?.catalog_item?.can_unlink) {
      result.push({ text: 'Unlink', onClick: () => console.log('Unlink'), icon: <LinkOffIcon />, color: 'error' });
    }

    return result;
  }, [productDetails, canUpdateCatalog]);

  return <StatusBar actions={actions} statuses={statuses} />;
};

const ProductStatusBar = ({ productDetails }: { productDetails: Product }) => {
  const isSeller = useUserData().isSeller();
  const isBuyer = useUserData().isBuyer();

  if (isBuyer) return <BuyerStatus productDetails={productDetails} />;
  if (isSeller) return <SellerStatus productDetails={productDetails} />;
  return null;
};

export default ProductStatusBar;
