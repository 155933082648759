import { StyledLoadingButton, TextFieldCounter, TwoButtonsWithHoverToggle } from "@/components";
import { ProductManagerPopupStatus } from "@/config";
import { Product } from "@/models";
import { useProcurementStore } from "@/zustand";
import { Check, ShoppingCart } from "@mui/icons-material";
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { Box, Button, Grid, Stack } from "@mui/material";
import { t } from "i18next";
import { Link as LinkIcon } from '@mui/icons-material';
import React, { useState } from "react";
import Link from "next/link";
import { sm } from "src/site-map";

type AlternativeProductRequisitionButtonProps = {
    product: Product,
    mainProductIsInCart?: boolean
    isPageAlternative?: boolean;
};

export const AlternativeProductRequisitionButton: React.VFC<AlternativeProductRequisitionButtonProps> = ({ product, mainProductIsInCart }) => {
    const [showRemoveButton, setShowRemoveButton] = React.useState(false);
    const [requisitionProductIsChanging, setrequisitionProductIsChanging] = useState(false);
    const { data, actions } = useProcurementStore(state => state.productFormManagerProperties)

    const handleAddProduct = () => actions.addProduct(product, true)
    const handleRemoveProduct = () => actions.removeProduct(product, true)

    return (
        <>{!mainProductIsInCart ? <></>
            : <Stack width="100%" onClick={(event) => event.stopPropagation()}>
                {product.inCart ?
                    (!showRemoveButton ?
                        <Button
                            onMouseOver={() => setShowRemoveButton(true)}
                            fullWidth
                            variant="outlined_background"
                            color="secondary"
                            startIcon={<Check />}>
                            {t("buttons.added")}
                        </Button>
                        :
                        <Button
                            onClick={handleRemoveProduct}
                            onMouseOut={() => setShowRemoveButton(false)}
                            fullWidth
                            variant="contained"
                            color="error">
                            {t("buttons.remove")}
                        </Button>
                    )
                    : <StyledLoadingButton onClick={handleAddProduct} loading={requisitionProductIsChanging} variant="contained" icon={<AddSharpIcon />} color="secondary" translateKey="add_as_alternative" />
                }
            </Stack>
        }
        </>
    )
}

export const SimilarProductRequisitionButton: React.VFC<AlternativeProductRequisitionButtonProps> = ({ product, seller }) => {
    const { data, actions } = useProcurementStore(state => state.productFormManagerProperties)
    const { currentRequisition, changeSimilarProductQuantity } = useProcurementStore()
    const handleOpenAddProductPopup = () => {
        // actions.setCandidateProductToAdd(product)
        // actions.setProductManagerPopupStatus(ProductManagerPopupStatus.ADD_SIMILAR_PRODUCT)
        actions.addProduct(product)
    }
    const handleRemoveProduct = () => actions.removeProduct(product)
    const handleChangeQuantity = (value: string) => changeSimilarProductQuantity(value, product)
    const handleChangeProductInCartQuantity = (value: string) => actions.changeQuantity(product, value)

    const product_id = product.id

    const productFromCart = React.useMemo(() => data.products.find(prod => prod.id === product_id), [product_id, data])
    const isProductInCart = productFromCart !== undefined

    return (
        <Grid container alignItems='center' justifyContent={'space-between'}>
            <Grid item md={5}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }} width="100%">
                    {isProductInCart ?
                        <TextFieldCounter
                            value={productFromCart.quantity}
                            changeQuantity={handleChangeProductInCartQuantity}
                        />
                        :
                        <TextFieldCounter
                            value={product.quantity}
                            changeQuantity={handleChangeQuantity}
                        />
                    }
                </Box>
            </Grid>
            <Grid item md={6}>
                <Stack width="100%" onClick={(event) => event.stopPropagation()}>

                    {isProductInCart ? <>
                        <TwoButtonsWithHoverToggle handleClickHiddenButton={handleRemoveProduct} hiddenButtonTitleKey="remove" mainButtonTitleKey={"added_to_po"} />
                    </>
                        : <Button variant="contained" onClick={handleOpenAddProductPopup} fullWidth startIcon={<ShoppingCart />}>
                            {t("buttons.add_to_PO")}
                        </Button>
                    }
                </Stack>
            </Grid>
        </Grid>
    )
}
export const SimilarProductQuoteButton: React.VFC<AlternativeProductRequisitionButtonProps> = ({ product }) => {
    const { data, actions } = useProcurementStore(state => state.productFormManagerProperties)
    const { changeSimilarProductQuantity } = useProcurementStore()
    const handleOpenAddProductPopup = () => {
        actions.addProduct(product)
    }
    const handleRemoveProduct = () => actions.removeProduct(product)
    const handleChangeQuantity = (value: string) => changeSimilarProductQuantity(value, product)
    const handleChangeProductInCartQuantity = (value: string) => actions.changeQuantity(product, value)

    const product_id = product.id

    const productFromCart = React.useMemo(() => data.products.find(prod => prod.id === product_id), [product_id, data])
    const isProductInCart = productFromCart !== undefined

    return (
        <Grid container alignItems='center' justifyContent={'space-between'}>
            <Grid item md={4}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }} width="100%">
                    {isProductInCart ?
                        <TextFieldCounter
                            value={productFromCart.quantity}
                            changeQuantity={handleChangeProductInCartQuantity}
                        />
                        :
                        <TextFieldCounter
                            value={product.quantity}
                            changeQuantity={handleChangeQuantity}
                        />
                    }
                </Box>
            </Grid>
            <Grid item md={7}>
                <Stack width="100%" onClick={(event) => event.stopPropagation()}>

                    {isProductInCart ? <>
                        <TwoButtonsWithHoverToggle handleClickHiddenButton={handleRemoveProduct} hiddenButtonTitleKey="remove" mainButtonTitleKey={"add_to_quote"} />
                    </>
                        : <Button disabled={!(isProductInCart?.catalog_item?.status === 'UNPUBLISHED' || product?.catalog_item?.status === 'PUBLISHED')} variant="contained" onClick={handleOpenAddProductPopup} fullWidth startIcon={<ShoppingCart />}>
                            {t("buttons.add_to_quote")}
                        </Button>
                    }
                </Stack>
            </Grid>
        </Grid>
    )
}

export const SimilarProductCatalogDraftListButton: React.VFC<AlternativeProductRequisitionButtonProps> = ({ product }) => {
    const { data, actions } = useProcurementStore(state => state.productFormManagerProperties)
    const formDetails = data?.formDetails;
    const handleOpenAddProductPopup = () => {
        actions.addProduct(product)
    }
    const handleRemoveProduct = () => actions.removeProduct(product)

    const product_id = product.id;
    const productFromCart = React.useMemo(() => data.products.find(prod => prod.id === product_id), [product_id, data]);
    const isProductInCart = productFromCart !== undefined;

    if (product.part_of_seller_catalog) {
        return (
            <Button variant='outlined_background' color='success' fullWidth startIcon={<LinkIcon />}>
                {t('buttons.published')}
            </Button>
        );
    }
    if (product.part_of_draft_list && product.part_of_draft_list.id !== formDetails?.id) {
        return (
            <Link href={sm.portals.seller.products.sellerProductList(`${product.part_of_draft_list.id}`).url}>
                <Button variant='outlined_background' color='primary' fullWidth sx={{ lineHeight: 1 }}>
                    Added To {product.part_of_draft_list.name}
                </Button>
            </Link>
        );
    }
    return (
        <Stack width='100%' onClick={event => event.stopPropagation()}>
            {isProductInCart ? (
                <>
                    <TwoButtonsWithHoverToggle
                        handleClickHiddenButton={handleRemoveProduct}
                        hiddenButtonTitleKey='remove'
                        mainButtonTitleKey={isProductInCart ? 'added_to_list' : 'add_to_list'}
                    />
                </>
            ) : (
                <Button variant='contained' onClick={handleOpenAddProductPopup} fullWidth>
                    {t('buttons.add_to_list')}
                </Button>
            )}
        </Stack>
    );
}