import { request } from '@/helpers';
import { SortConfig } from 'src/logic/models/@shared/sort';
import { EnvironmentFilters } from 'src/logic/models/catalogue/AdvancedFilters';
import { CatalogueBrand } from 'src/logic/models/catalogue/catalogue-content';
import { PaginatedResponse } from 'src/logic/models/queries';
import { CatalougeFilterItem } from '../products';

export const getBrandsIndex = () => {
  return request({ url: `/product/brands`, method: 'GET' });
};

export const getBrandDetails = (id: number, envFilters: EnvironmentFilters) => {
  return request({ url: `/product/brands/${id}`, method: 'GET', params: envFilters });
};

export const getSortedBrands = (id: string) => {
  return request({ url: `/product/brands?sort_by=${id}`, method: 'GET' });
};

// ================================ New Catalogue ================================

export type CatalogueBrandsTabResponse = {
  data: PaginatedResponse<CatalogueBrand>;
  sort_data: SortConfig;
};

export const getBrandsItems = ({
  page,
  search,
  sortBy,
  environmentFilters = {},
  filters,
}: {
  page: number;
  search?: string;
  sortBy?: string;
  environmentFilters?: EnvironmentFilters;
  filters?: CatalougeFilterItem[];
}): Promise<{ data: CatalogueBrandsTabResponse }> => {
  return request({
    url: '/product/catalog/brands_tab',
    method: 'POST',
    data: {
      query: search,
      page,
      sort_by: sortBy,
      filters,
      ...environmentFilters,
    },
  });
};

export const getMyBrands = ({
  page_number,
  search,
  environmentFilters,
  filters,
}: {
  page_number: number;
  search?: string;
  sortBy?: string;
  environmentFilters?: EnvironmentFilters;
  filters?: CatalougeFilterItem[];
}): Promise<{ data: CatalogueBrandsTabResponse }> => {
  return request({
    method: 'POST',
    url: '/seller/catalog/index-brands',
    data: {
      page_number: page_number || 1,
      filters,
      query: search,
      ...environmentFilters,
    },
  });
};

// =========== Store Brand
export const storeBrand = (brand: any, options = {}) => {
  return request({ url: `/brand/store`, method: 'POST', data: brand, ...options });
};
