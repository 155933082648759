import RotateRightIcon from '@mui/icons-material/RotateRight';
import { Button, Typography } from '@mui/material';
import { DescPopup } from '../description-popup';

type IProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const EditsUnderReviewPopup = ({ onClose, isOpen, onConfirm }: IProps) => {
  return (
    <DescPopup open={isOpen} handleClose={onClose}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem',
          padding: '1rem',
        }}
      >
        <RotateRightIcon
          sx={{ backgroundColor: '#FEE5CF', color: '#FB7F0D', borderRadius: '4px', padding: '4px', fontSize: '35px' }}
        />
        <Typography textAlign={'center'} color='#242833' fontSize={18} fontWeight={700}>
          Your edits are under review
        </Typography>
        <Typography textAlign={'center'} color='#242833' fontSize={13} fontWeight={400}>
          As part of our routine process, we review all edits before publishing them. You will be notified once the
          approval process is complete.
        </Typography>
        <Button
          variant='contained'
          type='button'
          color='primary'
          onClick={onConfirm}
          sx={{ px: 10, mt: 1, backgroundColor: '#0074FF', '&:hover': { backgroundColor: '#0074FF' } }}
        >
          Okay
        </Button>

        <Typography color='#8B8D98' fontSize={12} fontWeight={400} textAlign={'center'}>
          Learn more about our approval process
        </Typography>
      </div>
    </DescPopup>
  );
};
