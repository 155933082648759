export type SiteMap = {
  readonly [key: string]: { title: string, url: string } | SiteMap | Function;
  // readonly [key: string]: string | Function | SiteMap;
};

const makeSiteMap = <T extends SiteMap>(siteMap: T): T => siteMap;

//page url with the title to be shown in the breadcrumbs
//when adding a page it should also be added it to the BreadcrumbsConfig and RouteProtectionRules

export const sm = makeSiteMap({
  home: { title: 'home', url: '/' },

  contactUs: { title: 'contact_us', url: '/contact-us' },
  becomeASeller: { title: 'become_a_seller', url: '/become-a-seller' },
  careers: {
    index: { title: 'careers', url: '/careers' },
    id: (id = '[id]') => { return { title: id, url: `/careers/${id}` } },
  },
  searchResults: {
    index: { title: 'search_results', url: '/search-results' },
    id: (id = '[product]') => { return { title: id, url: `/search-results/${id}` } },
  },
  requisitions: {
    index: { title: 'requisitions', url: '/requisitions' },
    draft: { title: 'draft_requisition', url: '/requisitions/draft-requisition' },
    requisition: (requisitionId = '[requisition-id]') => { return { title: requisitionId, url: `/requisitions/${requisitionId}` } },
  },
  products: {
    index: { title: 'products', url: '/products' },
    id: (id = '[category-id]') => { return { title: id, url: `/products/${id}` } },
    classId: (id = '[category-id]', classId = '[class-id]') => { return { title: classId, url: `/products/${id}/${classId}` } },
    typeId: (id = '[category-id]', classId = '[class-id]', typeId = '[type-id]') => { return { title: typeId, url: `/products/${id}/${classId}/${typeId}` } },
    productId: (id = '[category-id]', classId = '[class-id]', typeId = '[type-id]', productId = '[product-id]') => { return { title: productId, url: `/products/${id}/${classId}/${typeId}/${productId}` } },
    alternatives: (id = '[category-id]', classId = '[class-id]', typeId = '[type-id]', productId = '[product-id]') => { return { title: 'alternatives', url: `/products/${id}/${classId}/${typeId}/${productId}/alternatives` } },
    similar: (id = '[category-id]', classId = '[class-id]', typeId = '[type-id]', productId = '[product-id]') => { return { title: 'similar', url: `/products/${id}/${classId}/${typeId}/${productId}/similar` } },
    alternativeId: (id = '[category-id]', classId = '[class-id]', typeId = '[type-id]', productId = '[product-id]', alternativeId = '[alternative-id]') => { return { title: alternativeId, url: `/products/${id}/${classId}/${typeId}/${productId}/alternatives/${alternativeId}` } },
  },

  myCatalogue: {
    index: { title: 'My_Catalogue', url: '/my-catalogue' },
    myBrands: { title: 'My_Brands', url: '/my-catalogue/my-brands' }
  },
  manageCatalogue: { title: 'Manage_Catalogue', url: '/manage-catalogue' },
  linkDirectory: { title: 'Link_From_Directory', url: '/link-from-directory' },

  catalogue: {
    index: { title: 'Catalogue', url: '/catalogue' },
    indexBuyer: { title: 'Directory', url: '/catalogue' },
    indexSeller: { title: 'General_Directory', url: '/catalogue' },
    searchResults: { title: 'search_results', url: '/catalogue/search-results' },
    products: {
      index: { title: 'All Products', url: '/catalogue/products' }, // Groups Index
      groupId: (groupId = '[group-id]') => { return { title: groupId, url: `/catalogue/products/${groupId}` } }, // Group Details (Categories)
      categoryId: (groupId = '[group-id]', categoryId = '[category-id]') => { return { title: categoryId, url: `/catalogue/products/${groupId}/${categoryId}` } }, // Category Details (Classes)
      classId: (groupId = '[group-id]', categoryId = '[category-id]', classId = '[class-id]') => { return { title: classId, url: `/catalogue/products/${groupId}/${categoryId}/${classId}` } }, // Class Details (Types)
      productId: (productId = '[product-id]') => { return { title: productId, url: `/catalogue/products/product-details/${productId}` } }, // Product Details Page
      similar: (productId = '[product-id]') => { return { title: productId, url: `/catalogue/products/product-details/${productId}/similar` } }, // Similar Products Page
      alternatives: (productId = '[product-id]') => { return { title: productId, url: `/catalogue/products/product-details/${productId}/alternatives` } }, // Alternatives Products Page
      typeId: (typeId = '[type-id]') => { return { title: typeId, url: `/catalogue/products/types/${typeId}` } }, // Type Details (Search Results)
      add: { title: 'add', url: '/catalogue/products/add' }, // add new item
      editProduct: (productId = '[product-id]') => { return { title: productId, url: `/catalogue/products/product-details/${productId}/edit` } }, // Product Details Page
    },
    services: {
      index: { title: 'All Services', url: '/catalogue/services' }, // Groups Index
      groupId: (groupId = '[group-id]') => { return { title: groupId, url: `/catalogue/services/${groupId}` } }, // Group Details (Categories)
      categoryId: (groupId = '[group-id]', categoryId = '[category-id]') => { return { title: categoryId, url: `/catalogue/services/${groupId}/${categoryId}` } }, // Category Details (Classes)
      classId: (groupId = '[group-id]', categoryId = '[category-id]', classId = '[class-id]') => { return { title: classId, url: `/catalogue/services/${groupId}/${categoryId}/${classId}` } }, // Class Details (Types)
    },
    brands: {
      index: { title: 'brands', url: '/catalogue/brands' },
      brandId: (brandId = '[brand-id]') => { return { title: brandId, url: `/catalogue/brands/${brandId}` } }
    },
    sellers: {
      index: { title: 'sellers', url: '/catalogue/sellers' },
      sellerId: (sellerId = '[seller-id]') => { return { title: sellerId, url: `/catalogue/sellers/${sellerId}` } }
    },
  },

  login: { title: 'login', url: '/login' },
  register: {
    index: { title: 'register', url: '/register' },
    asBuyer: {
      index: { title: 'register_buyer', url: '/register/buyer' },
    },
    asSeller: {
      index: { title: 'register_seller', url: '/register/seller' },
    },
    thankYou: { title: 'register_success', url: '/register/thank-you' },
    somethingWentWrong: { title: 'register_wrong', url: '/register/something-went-wrong' },
    verificationExpired: { title: 'verification_expired', url: '/register/verification-expired' },
    verificationSuccess: { title: 'verification_success', url: '/register/verification-success' },
    alreadyConfirmed: { title: 'register_already_confirmed', url: '/register/already-confirmed' },
    alreadyRegistered: { title: 'register_already_registered', url: '/register/already-registered' },
    invitationExpired: { title: 'register_invitation_expired', url: '/register/invitation-expired' },
  },

  account: {
    index: { title: 'account_management', url: '/account-management' },
    editProfile: { title: 'edit_profile', url: '/account-management/edit-profile' }
  },
  portals: {
    index: { title: 'portal', url: '/portal' },
    buyer: {
      index: { title: 'portal_buyer', url: '/portal/buyer' },
      dashboard: { title: 'dashboard_buyer', url: '/portal/buyer/dashboard' },
      documentsCenter: { title: 'documents_center_buyer', url: '/portal/buyer/documents-center' },
      sellers: {
        index: { title: 'my_sellers', url: '/portal/buyer/sellers' },
        index_Title: { title: 'sellers', url: '/portal/buyer/sellers' },
        allSellers: {
          index: { title: 'all_sellers', url: '/portal/buyer/sellers/all-sellers' },
        },
        sellerId: (sellerId = '[seller-id]') => { return { title: sellerId, url: `/portal/buyer/sellers/${sellerId}` } }
      },
      users: { title: 'users_buyer', url: '/portal/buyer/users' },
      usersAccount: { title: 'user_account_buyer', url: '/portal/buyer/users/company-user-profile' },
      procurement: {
        index: { title: 'procurement', url: '/portal/buyer/procurement' },
        requisitions: {
          index: { title: 'requisitions_buyer', url: '/portal/buyer/procurement/requisitions' },
          requisition: (requisitionId = '[requisition-id]') => { return { title: requisitionId, url: `/portal/buyer/procurement/requisitions/${requisitionId}` } },
        },
        bundleRFQs: {
          bundleRFQ: (bundleRFQId = '[bundleRFQ-id]') => { return { title: bundleRFQId, url: `/portal/buyer/procurement/bundleRFQs/${bundleRFQId}` } },
          edit: (bundleRFQId = '[bundleRFQ-id]') => { return { title: bundleRFQId, url: `/portal/buyer/procurement/bundleRFQs/${bundleRFQId}/edit` } },
        },
        requestForQuotes: {
          index: { title: 'request_for_quotes', url: '/portal/buyer/procurement/request-for-quotes' },
          RequestForQuote: (requestForQuoteName = '[request-for-quotes-id]') => { return { title: requestForQuoteName, url: `/portal/buyer/procurement/request-for-quotes/${requestForQuoteName}` } },
        },
        quotes: {
          index: { title: 'quotes', url: '/portal/buyer/procurement/quotes' },
          quoteId: (quoteId = '[quote-id]') => { return { title: quoteId, url: `/portal/buyer/procurement/quotes/${quoteId}` } },
        },
        comparisonTables: {
          index: { title: 'comparison_tables', url: '/portal/buyer/procurement/comparison-tables' },
          ctId: (ctId = '[ct-id]') => { return { title: ctId, url: `/portal/buyer/procurement/comparison-tables/${ctId}` } },
        },
        purchaseOrders: {
          index: { title: 'purchase_orders', url: '/portal/buyer/procurement/purchase-orders' },
          purchaseOrderId: (PurchaseOrderId = '[purchase-order-id]') => { return { title: PurchaseOrderId, url: `/portal/buyer/procurement/purchase-orders/${PurchaseOrderId}` } },
          bundlePurchaseOrderId: (bundlePurchaseOrderId = '[bundle-purchase-order-id]') => { return { title: bundlePurchaseOrderId, url: `/portal/buyer/procurement/bundle-purchase-orders/${bundlePurchaseOrderId}` } },
          edit: (PurchaseOrderId = '[purchase-order-id]') => { return { title: PurchaseOrderId, url: `/portal/buyer/procurement/purchase-orders/${PurchaseOrderId}/edit` } },
          editBundlePurchaseOrderId: (bundlePurchaseOrderId = '[bundle-purchase-order-id]') => { return { title: bundlePurchaseOrderId, url: `/portal/buyer/procurement/bundle-purchase-orders/${bundlePurchaseOrderId}/edit` } },
          editBundlePurchaseOrderIdCT: (bundlePurchaseOrderId = '[bundle-purchase-order-id]') => { return { title: bundlePurchaseOrderId, url: `/portal/buyer/procurement/bundle-purchase-orders/${bundlePurchaseOrderId}/edit/comparison-table` } },
        }
      },
      projects: {
        index: { title: 'projects_buyer', url: '/portal/buyer/projects' },
        projectId: (projectId = '[project-id]') => { return { title: projectId, url: `/portal/buyer/projects/${projectId}` } }
      },
    },
    seller: {
      index: { title: 'portal_seller', url: '/portal/seller' },
      dashboard: { title: 'dashboard_seller', url: '/portal/seller/dashboard' },
      documentsCenter: { title: 'documents_center_seller', url: '/portal/seller/documents-center' },
      buyers: {
        index: { title: 'my_buyers', url: '/portal/seller/buyers' },
        index_Title: { title: 'buyers', url: '/portal/seller/buyers' },
        allBuyers: {
          index: { title: 'all_buyers', url: '/portal/seller/buyers/all-buyers' },
        },
        buyerId: (buyerId = '[buyer-id]') => { return { title: buyerId, url: `/portal/seller/buyers/${buyerId}` } }
      },
      users: { title: 'users_seller', url: '/portal/seller/users' },
      usersAccount: { title: 'user_account_seller', url: '/portal/seller/users/company-user-profile' },
      // requisitions: { title: 'requisitions_seller', url: '/portal/seller/requisitions' },
      projects: {
        index: { title: 'projects_seller', url: '/portal/seller/projects' },
        projectId: (projectId = '[project-id]') => { return { title: projectId, url: `/portal/seller/projects/${projectId}` } }
      },
      catalogue: { title: 'catalogue_seller', url: '/portal/seller/catalogue' },
      products: {
        index: { title: 'my_products', url: '/portal/seller/products' },
        sellerProductList: (sellerProductListId = '[product-list-id]') => { return { title: sellerProductListId, url: `/portal/seller/products/${sellerProductListId}` } }
      },
      procurement: {
        index: { title: 'procurement', url: '/portal/seller/procurement' },
        requestForQuotes: {
          index: { title: 'request_for_quotes', url: '/portal/seller/procurement/request-for-quotes' },
          requestForQuote: (requestForQuoteId = '[rfq-id]') => { return { title: requestForQuoteId, url: `/portal/seller/procurement/request-for-quotes/${requestForQuoteId}` } }
        },
        quotes: {
          index: { title: 'quotes', url: '/portal/seller/procurement/quotes' },
          quoteId: (quoteId = '[quote-id]') => { return { title: quoteId, url: `/portal/seller/procurement/quotes/${quoteId}` } },
          edit: (quoteId = '[quote-id]') => { return { title: quoteId, url: `/portal/seller/procurement/quotes/${quoteId}/edit` } },
        },
        purchaseOrders: {
          index: { title: 'purchase_orders', url: '/portal/seller/procurement/purchase-orders' },
          purchaseOrderId: (id = '[purchase-order-id]') => { return { title: id, url: `/portal/seller/procurement/purchase-orders/${id}` } }
        },
      },
    },
    external: {
      seller: {
        procurement: {
          requestForQuotes: {
            requestForQuote: (requestForQuoteId = '[rfq-id]') => { return { title: requestForQuoteId, url: `/portal/external/seller/procurement/request-for-quotes/${requestForQuoteId}` } }
          },
          quotes: {
            quoteId: (quoteId = '[quote-id]') => { return { title: quoteId, url: `/portal/external/seller/procurement/quotes/${quoteId}` } }
          },
          purchaseOrders: {
            purchaseOrderId: (id = '[purchase-order-id]') => { return { title: id, url: `/portal/external/seller/procurement/purchase-orders/${id}` } }
          },
        }
      },
      buyer: {
        procurement: {
          quotes: {
            quoteId: (quoteId = '[quote-id]') => { return { title: quoteId, url: `/portal/external/buyer/procurement/quotes/${quoteId}` } }
          },
        }
      }
    },
  },
});