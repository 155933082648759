import { StyledPopper } from '@/components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, Typography } from '@mui/material';
import React from 'react';

type ListPricePopupProps = {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClosePopper: () => void;
  listPrice?: number | undefined;
};

export const ListPricePopup: React.VFC<ListPricePopupProps> = ({ open, anchorEl, handleClosePopper, listPrice }) => {
  return (
    <>
      <StyledPopper open={open} anchorEl={anchorEl} handleClose={handleClosePopper}>
        <div style={{ maxWidth: '300px' }}>
          <Grid container md={12} p={2}>
            <InfoOutlinedIcon sx={{ fontSize: '22px !important', color: 'primary.main' }} />

            <Typography variant='h6' color='primary.main' fontWeight={700} pl={1} pb={2}>
              Offered Price
            </Typography>

            <Grid container md={12} justifyContent={'start'} alignItems={'center'}>
              <Typography variant='body2' color='text.disabled'>
                <b style={{ marginRight: 1 }}>Price:</b> This is your PO price for this product and is subject to
                approval from the seller.
              </Typography>
            </Grid>
            <Grid container md={12} justifyContent={'start'} alignItems={'center'} pt={2}>
              <Typography variant='body2' color='text.disabled'>
                <b style={{ marginRight: 1 }}>List Price:</b> {listPrice ? `AED  ${listPrice}` : 'No Price Added'}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </StyledPopper>
    </>
  );
};
