import { ResponseHandlers } from 'src/logic/models/queries';
import {
    linkProduct,
    linkSelectedProductsService,
    unlinkCatalogueItemService,
    unlinkCatalogueItemsService,
    updateCatalogueItemService,
    updateCatalogueItemsService
} from 'src/logic/services/catalogue/private-seller';
import create from 'zustand';
import { useAdvancedFiltersSlice } from "../advanced-filters";

export interface ManageCatalogueSlice {
    linkProduct: (productId: number, responseHandlers?: ResponseHandlers) => void;
    unlinkCatalogueItem: (catalogueItemId: number, responseHandlers?: ResponseHandlers) => void;
    linkSelectedCatalogueItems: (productIds: number[], responseHandlers?: ResponseHandlers) => void;
    unlinkAndRemoveItem: (catalogueItemId: number, responseHandlers?: ResponseHandlers) => void;
    updateCatalogueItem: (catalogueItemId: number, data: {}, responseHandlers?: ResponseHandlers) => void;
    unlinkAndRemoveSelectedCatalogueItems: (catalogueItemsIds: number[], responseHandlers?: ResponseHandlers) => void;
    unlinkSelectedCatalogueItems: (productsIds: number[], responseHandlers?: ResponseHandlers) => void;
    changePublicationStatusOfSelectedCatalogueItems: (active: boolean, catalogueItemsIds: number[], responseHandlers?: ResponseHandlers) => void;
}

export const useManageCatalogueSlice = create<ManageCatalogueSlice>((set, get) => ({
    updateCatalogueItem: (catalogueItemId: number, data: {}, responseHandlers?: ResponseHandlers) => {
        const results = useAdvancedFiltersSlice.getState().properties.results;
        if (!results) return;

        updateCatalogueItemService(catalogueItemId, data)
            .then((response) => {
                const updatedProduct = response?.data;
                if (!updatedProduct) {
                    console.error('Updated product data is undefined or null.');
                    return;
                }

                const newResults = results.map((productItem) => {
                    return productItem.catalog_item?.id !== catalogueItemId ? productItem : updatedProduct;
                });

                useAdvancedFiltersSlice.setState((state) => ({
                    properties: { ...state.properties, results: newResults },
                }));

                responseHandlers?.onSuccess?.(response.data);
            })
            .catch((err) => {
                responseHandlers?.onError?.(err);
            });
    },

    linkProduct: (productId: number, responseHandlers?: ResponseHandlers) => {
        linkProduct(productId)
            .then((data) => {
                const updatedProduct = data?.data;
                if (!updatedProduct) {
                    console.error('Updated product data is undefined or null.');
                    return;
                }

                const results = useAdvancedFiltersSlice.getState().properties.results.map((productItem) =>
                    productItem.id !== productId ? productItem : updatedProduct
                );

                useAdvancedFiltersSlice.setState((state) => ({
                    properties: { ...state.properties, results },
                }));

                responseHandlers?.onSuccess?.(data.data);
            })
            .catch((err) => {
                responseHandlers?.onError?.(err);
            });
    },

    unlinkCatalogueItem: (catalogueItemId: number, responseHandlers?: ResponseHandlers) => {
        const results = useAdvancedFiltersSlice.getState().properties.results;
        if (!results) return;

        unlinkCatalogueItemService(catalogueItemId)
            .then((data) => {
                const updatedProduct = data?.data;
                if (!updatedProduct) {
                    console.error('Updated product data is undefined or null.');
                    return;
                }

                const newResults = results.map((productItem) =>
                    productItem.catalog_item?.id !== catalogueItemId ? productItem : updatedProduct
                );

                useAdvancedFiltersSlice.setState((state) => ({
                    properties: { ...state.properties, results: newResults },
                }));

                responseHandlers?.onSuccess?.(data.data);
            })
            .catch((err) => {
                responseHandlers?.onError?.(err);
            });
    },

    linkSelectedCatalogueItems: (productIds: number[], responseHandlers?: ResponseHandlers) => {
        const results = useAdvancedFiltersSlice.getState().properties.results;
        if (!results) return;

        const productsPayload = { products: productIds };
        linkSelectedProductsService(productsPayload)
            .then((data) => {
                responseHandlers?.onSuccess?.(data.data);
            })
            .catch((err) => {
                responseHandlers?.onError?.(err);
            });
    },

    unlinkAndRemoveItem: (catalogueItemId: number, responseHandlers?: ResponseHandlers) => {
        const results = useAdvancedFiltersSlice.getState().properties.results;
        if (!results) return;

        unlinkCatalogueItemService(catalogueItemId)
            .then((data) => {
                responseHandlers?.onSuccess?.(data.data);
            })
            .catch((err) => {
                responseHandlers?.onError?.(err);
            });
    },

    unlinkAndRemoveSelectedCatalogueItems: (catalogueItemsIds: number[], responseHandlers?: ResponseHandlers) => {
        const results = useAdvancedFiltersSlice.getState().properties.results;
        if (!results) return;

        const catalogItemsPayload = { catalog_item_ids: catalogueItemsIds };
        unlinkCatalogueItemsService(catalogItemsPayload)
            .then((response) => {
                responseHandlers?.onSuccess?.(response.data);
            })
            .catch((err) => {
                responseHandlers?.onError?.(err);
            });
    },

    unlinkSelectedCatalogueItems: (productsIds: number[], responseHandlers?: ResponseHandlers) => {
        const results = useAdvancedFiltersSlice.getState().properties.results;
        if (!results) return;

        const catalogItemsIds = productsIds?.map((productId) => {
            const productItem = results.find((item) => item?.id === productId);
            return productItem?.catalog_item?.id;
        }).filter((id) => id !== undefined);

        const catalogItemsPayload = { catalog_item_ids: catalogItemsIds };
        unlinkCatalogueItemsService(catalogItemsPayload)
            .then((response) => {
                responseHandlers?.onSuccess?.(response.data);
            })
            .catch((err) => {
                responseHandlers?.onError?.(err);
            });
    },

    changePublicationStatusOfSelectedCatalogueItems: (active: boolean, catalogueItemsIds: number[], responseHandlers?: ResponseHandlers) => {
        const results = useAdvancedFiltersSlice.getState().properties.results;
        if (!results) return;

        const catalogItemsPayload = { catalog_items: catalogueItemsIds.map((id) => ({ id, active })) };
        updateCatalogueItemsService(catalogItemsPayload)
            .then((response) => {
                const updatedProducts = response?.data;
                if (!updatedProducts || !Array.isArray(updatedProducts)) {
                    console.error('Updated products data is undefined or not an array.');
                    return;
                }

                const newResults = results.map((productItem) => {
                    const updatedProduct = updatedProducts.find(
                        (updated) => updated.catalog_item?.id === productItem.catalog_item?.id
                    );

                    if (updatedProduct) {
                        return updatedProduct;
                    }
                    return productItem;
                });

                useAdvancedFiltersSlice.setState((state) => ({
                    properties: { ...state.properties, results: newResults },
                }));

                responseHandlers?.onSuccess?.(response.data);
            })
            .catch((err) => {
                responseHandlers?.onError?.(err);
            });
    },

}));
