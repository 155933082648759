import { SvgIconProps, SvgIcon } from '@mui/material';

export const DeleteIcon: React.FC<SvgIconProps> = ({ color = '#FF4D4D', fontSize = 'inherit', ...props }) => (
  <SvgIcon
    fontSize={fontSize}
    width='14'
    height='16'
    viewBox='0 0 14 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M2.8316 15.5C2.37327 15.5 1.98091 15.3368 1.65452 15.0104C1.32813 14.684 1.16494 14.2917 1.16494 13.8333V3C0.928826 3 0.730909 2.92014 0.571187 2.76042C0.411465 2.60069 0.331604 2.40278 0.331604 2.16667C0.331604 1.93056 0.411465 1.73264 0.571187 1.57292C0.730909 1.41319 0.928826 1.33333 1.16494 1.33333H4.49827C4.49827 1.09722 4.57813 0.899306 4.73785 0.739583C4.89757 0.579861 5.09549 0.5 5.3316 0.5H8.66493C8.90104 0.5 9.09896 0.579861 9.25868 0.739583C9.41841 0.899306 9.49827 1.09722 9.49827 1.33333H12.8316C13.0677 1.33333 13.2656 1.41319 13.4253 1.57292C13.5851 1.73264 13.6649 1.93056 13.6649 2.16667C13.6649 2.40278 13.5851 2.60069 13.4253 2.76042C13.2656 2.92014 13.0677 3 12.8316 3V13.8333C12.8316 14.2917 12.6684 14.684 12.342 15.0104C12.0156 15.3368 11.6233 15.5 11.1649 15.5H2.8316ZM11.1649 3H2.8316V13.8333H11.1649V3ZM4.49827 12.1667H6.16493V4.66667H4.49827V12.1667ZM7.8316 12.1667H9.49827V4.66667H7.8316V12.1667Z'
      fill={color}
    />
  </SvgIcon>
);
