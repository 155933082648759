import React from 'react';

export const iconStyles: React.CSSProperties = {
  backgroundColor: '#EAEEF6',
  aspectRatio: '1',
  width: '40px',
  height: '40px',
  marginInlineEnd: '.75rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
};

export const productHistoryTextStyles: React.CSSProperties = {
  cursor: 'pointer',
  display: 'inline-block',
  transform: 'translateY(-5px)',
  lineHeight: 1,
  fontSize: 13,
  fontWeight: 700,
  color: '#0074FF',
  paddingInlineStart: '.35rem',
};


export const GeneralContainer: React.CSSProperties = {
  backgroundColor:'#fff',
  borderRadius: '8px'
};